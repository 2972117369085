import React                from 'react'
import {
  reduce
}                           from 'lodash-es'
import {
  Button,
  Container,
  Col,
  Row
}                           from 'react-bootstrap'
import moment               from 'moment-timezone'
import cx                   from 'utils/classnames'
import RawHtml              from 'entrada-ui/RawHtml'
import Typography           from 'entrada-ui/Typography'
import { FareCodeToText }   from 'components/BookingWizard'
import styles               from './styles.module.scss'

const CheckoutItem = (props) => {
  const {
    bookingItemRef,
    tourName,
    departureDate,
    tourDurationHours,
    tourDurationMinutes,
    cardImage,
    pickup,
    dropoff,
    fares,
    total
  } = props

  const passengersComposition = reduce(
    fares,
    (acc, numberOfPassengers, fareCode) => {
        // - Generate base price description based on the composition and price
        acc.push(
          `${numberOfPassengers} ${FareCodeToText(fareCode, numberOfPassengers > 1)}`
        )

        return acc
    },
    []
  )

  return (
    <>
        <Row className="d-flex-space-between">
            <Col xs={8}>
                <Typography className={styles.tourHeader} variant="h5">
                  {tourName}
                </Typography>
            </Col>
            <Col>
                <Typography className={cx(styles.tourHeader, 'text-right')}>
                    ${total.toFixed(2)}
                </Typography>
            </Col>
        </Row>

        <Row>
            <Col>
                <div className="mb-2">
                    <p className='mb-0'>{moment(departureDate).format('DD MMM, YYYY')}</p>
                    <p className='mb-0'>{passengersComposition.join(', ')}</p>
                    {pickup &&
                    <p className='mb-0'>Departs: {pickup.time} at {pickup.description}</p>
                    }
                    {dropoff &&
                    <p className='mb-0'>Return: {dropoff.time} at {dropoff.description}</p>
                    }
                </div>
                <p>
                    {/* <b>Free cancellation before {moment(departureDate).subtract(1, 'day').format('DD MMM, YYYY')}</b> */}
                    <b>Free cancellation up to 2 hours before departure</b>
                </p>
            </Col>
        </Row>

        <hr className="mt-3 mb-3" />
    </>
  )
}

export default CheckoutItem

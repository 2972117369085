import React, { Component } from 'react'
import { navigate }         from 'gatsby'
import {
    map
}                           from 'lodash-es'
import cx                   from 'utils/classnames'
import {
    Button,
    Container,
    Col,
    OverlayTrigger,
    Tooltip,
    Row,
}                           from 'react-bootstrap'
import {
    FaClock,
    FaCcVisa,
    FaCcMastercard,
    FaArrowLeft,
    FaQuestionCircle,
    FaCreditCard
}                           from 'react-icons/fa'
import {
    Form,
    Field,
    FormSpy
}                           from 'react-final-form'
import { usePaymentInputs } from 'react-payment-inputs'
import Checkbox             from 'entrada-ui/Checkbox'
import Icon                 from 'entrada-ui/Icon'
import RawHtml              from 'entrada-ui/RawHtml'
import Select               from 'entrada-ui/Select'
import TextFieldComp        from 'entrada-ui/TextField'
import Typography           from 'entrada-ui/Typography'
import AutoSubmitForm       from 'components/AutoSubmitForm'
import CartHandler          from 'components/CartHandler'
import Guarantee            from 'components/Checkout/Guarantee'
import CheckoutItem         from 'components/Checkout/CheckoutItem'
import Link                 from 'components/Link'
import Modal                from 'components/Modal'
import phoneCodes           from 'utils/phone-codes'
import {
    scrollToTarget,
    isJwtExpired
}                           from 'utils'
import styles               from './styles.module.scss'
import { Logtail }          from "@logtail/browser"

const logtail = new Logtail("59bN3x1Js3pQgUzkAnyTG39A")

const TRANSACTION_ERROR_STATUSES = [
    'INVALID_DATA',

    'DECLINED',
    'DECLINED_RETRY_TRANSACTION',

    'UNKNOWN_RETRY_STATUS',
    'TIMEDOUT_OR_CANCELLED',

    'NOT_FOUND',
]

const TRANSACTION_DECLINED_ERROR_STATUSES = [
    'DECLINED',
    'DECLINED_RETRY_TRANSACTION',
]

const TRANSACTION_TRY_AGAIN_ERROR_STATUSES = [
    'UNKNOWN_RETRY_STATUS',
    'TIMEDOUT_OR_CANCELLED',
]

const TextField = (props) => (
    <TextFieldComp
        {...props}
        fullWidth={true}
        InputProps={{
            classes: {
                root: styles.textInput,
                error: styles.textInputError
            }
        }}
    />
)

const CheckoutTemplate = (props) => {

    const {
        pageContext: {
            data: {
                wordpressPage: {
                    content,
                    title
                },
                termsAndConditions
            }
        }
    } = props
    let termsAndConditions1 = content

    const {
        meta,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
    } = usePaymentInputs()

    const phonePrefixes = React.useMemo(() => (
        map(phoneCodes, item => ({
            label: item.country,
            value: item.code,
        }))
    ), [phoneCodes])

    const context = React.useContext(CartHandler.Context)
    const { apiToken, cartItems, cartTotal, addPassengerDetails, pay, paymentForm, paymentStatus, resetCart, setSessionTokenExpired } = context

    const [showTermsModal, setShowTermsModal] = React.useState(false)
    const [showTransactionError, setShowTransactionError] = React.useState(false)
    const [disableButton, setDisableButton] = React.useState(false)
    
    React.useEffect(() => {
        if(apiToken && isJwtExpired(apiToken)) {
            setSessionTokenExpired(true)
        }        
    }, [apiToken])

    const initialValues = React.useMemo(() => {
        const values = {
            firstName: '',
            lastName: '',
            phonePrefix: {
                value: "64",
                label: "New Zealand (+64)"
            },
            phoneNumber: '',
            cardName: '',
            cardNumber: '',
            cardExpiryDate: '',
            cvc: '',
        }

        return values
    }, [])

    const handleSubmit = React.useCallback(async (data) => {

        logtail.info("checkout sent")

        if (!disableButton) {
            setDisableButton(true)
        }

        addPassengerDetails({
            ...data,
            phonePrefix: data.phonePrefix.value
        })
        .then((res) => {
            const {
                cardName,
                cardNumber,
                cardExpiryDate,
                cvc
            } = data
            
            if(res && res.errors && res.errors.length) {
                throw res.errors
            }

            const [cardExpiryMonth, cardExpiryYear] = cardExpiryDate.replace(/\s/g, '').split('/')
            return pay({
                CardHolderName: cardName,
                CardNumber: cardNumber.replace(/\s/g, ''),
                ExpiryMonth: cardExpiryMonth,
                ExpiryYear: cardExpiryYear,
                Cvc2: cvc,
            })
        }).catch(errors => {

            logtail.error("checkout error", errors)

            // handle server jwt errors
            if (Array.isArray(errors?.graphQLErrors) && errors.graphQLErrors.find(err => err.type === 'TokenExpired' || err.type === 'AuthError')) {
                setSessionTokenExpired(true)
            }    
        }).finally(() => {
            logtail.flush()
        })        
    })

    const isRequired = value => (value ? undefined : 'Required')
    const isEmail = value => (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 'Must be an email' : undefined)

    const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

    // We want to render the form in the server side by default, so we only reset the cart
    // and redirect in the browser side.
    if(typeof window !== 'undefined' && !apiToken) {
        resetCart()
        // NOTE For some reason if we use navigate('/cart') it's VERY slow
        window.location.href = '/cart'
        return <></>
    }

    return (
        <>
            {paymentForm &&
            <AutoSubmitForm actionUrl={process.env.GATSBY_PXFUSION_URL} params={paymentForm} />
            }
            <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validate={(values) => {
                    let errors = {}
                    if (meta.erroredInputs.cardNumber) {
                    errors.cardNumber = meta.erroredInputs.cardNumber
                    }

                    if (meta.erroredInputs.cardExpiryDate) {
                    errors.cardExpiryDate = meta.erroredInputs.cardExpiryDate
                    }

                    if (meta.erroredInputs.cvc) {
                    errors.cvc = meta.erroredInputs.cvc
                    }

                    return errors;
                }}
                mutators={{
                    onAcceptTerms: ([value], state, utils) => {
                        utils.changeValue(state, 'acceptTerms', (old) => value)
                    }
                }}
                render={({ handleSubmit, submitting, pristine, values, form: { mutators } }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Container className="pt-4 pb-4">
                                <Typography className="mt-0 mb-3" variant="h1">
                                    Secure Checkout
                                </Typography>
                                <p className='mb-4'>You're just moments away from confirming your awesomeNZ experience!</p>

                                <Row>
                                    <Col lg={8}>
                                        {TRANSACTION_ERROR_STATUSES.includes(paymentStatus) &&
                                        <Container className={cx('pt-4 pb-4 mb-4', styles.error)}>
                                            <Icon className={styles.errorIcon} fontSize="large">
                                                <FaCreditCard />
                                            </Icon>
                                            <span>
                                                {paymentStatus === 'INVALID_DATA' &&
                                                'The form data recieved was invalid. Your credit card was not charged. Please contact the site administrator. This is an unexpected error.'
                                                }
                                                {paymentStatus === 'NOT_FOUND' &&
                                                'The payment session id was not found. Your credit card was not charged. Please contact the site administrator. This is an unexpected error.'
                                                }
                                                {TRANSACTION_DECLINED_ERROR_STATUSES.includes(paymentStatus) &&
                                                'The credit card you provided was declined. Please try paying with a different card.'
                                                }
                                                {TRANSACTION_TRY_AGAIN_ERROR_STATUSES.includes(paymentStatus) &&
                                                'The transaction was not successful. Your credit card was not charged. Please try again.'
                                                }
                                            </span>
                                        </Container>
                                        }

                                        <Container className={cx('mb-4', styles.formBlock)}>
                                            <Typography className="mt-0 mb-1" variant="h2" color="brand-color-2">
                                                Lead Passenger Details
                                            </Typography>
                                            <p>Information we need to confirm your tour</p>

                                            <Row>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="firstName">First Name*</label>
                                                    <Field
                                                        name="firstName"
                                                        validate={isRequired}
                                                        render={({ input, meta, ...props }) => (
                                                            <TextField {...input} {...meta} {...props}/>
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="lastName">Last Name*</label>
                                                    <Field
                                                        name="lastName"
                                                        validate={isRequired}
                                                        render={({ input, meta, ...props }) => (
                                                            <TextField {...input} {...meta} {...props}/>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="phoneNumber">Phone Number*</label>

                                                    <Row>
                                                        <Col xs={12} lg={7} className="pr-lg-0 mb-4 mb-lg-0">
                                                            <Field
                                                                name="phonePrefix"
                                                                className={styles.select}
                                                                options={phonePrefixes}
                                                                clearable={false}
                                                                validate={isRequired}
                                                                render={({ input, meta, ...props }) => (
                                                                    <Select {...input} {...meta} {...props} />
                                                                )}
                                                            />
                                                        </Col>
                                                        <Col xs={12} lg={5}>
                                                            <Field
                                                                name="phoneNumber"
                                                                validate={isRequired}
                                                                render={({ input, meta, ...props }) => (
                                                                    <TextField {...input} {...meta} {...props}/>
                                                                )}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="email">Email address*</label>
                                                    <Field
                                                        name="email"
                                                        validate={composeValidators(isRequired, isEmail)}
                                                        render={({ input, meta, ...props }) => (
                                                            <TextField {...input} {...meta} {...props}/>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container className={cx('mb-4', styles.formBlock)}>
                                            <Typography className="mt-0 mb-1" variant="h2" color="brand-color-2">
                                                Payment Details
                                            </Typography>
                                            <p>Pay securely - we use SSL encryption to keep your data safe</p>


                                            <Row>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="cardName">Cardholder Name *</label>
                                                    <Field
                                                        name="cardName"
                                                        placeholder="John Doe"
                                                        validate={isRequired}
                                                        render={({ input, meta, ...props }) => (
                                                            <TextField {...input} {...meta} {...props}/>
                                                        )}
                                                    />
                                                </Col>

                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="cardNumber">Credit Card Number *</label>
                                                    <Field
                                                        name="cardNumber"
                                                        fullWidth={true}
                                                        placeholder="4242 4242 4242 4242"
                                                        render={({ input: { onChange, onBlur, ...input}, meta, ...props }) => {
                                                            const {
                                                                ref,
                                                                ...ccNumberProps
                                                            } = getCardNumberProps({ onChange, onBlur })

                                                            return (
                                                                <TextField {...ccNumberProps} inputRef={ref} {...input} {...meta} {...props}/>
                                                            )
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="cardExpiryDate">Expiration Date *</label>

                                                    <Field
                                                        name="cardExpiryDate"
                                                        fullWidth={true}
                                                        placeholder="MM/YY"
                                                        render={({ input: { onChange, onBlur, ...input}, meta, ...props }) => {
                                                            const {
                                                                ref,
                                                                ...ccExpireProps
                                                            } = getExpiryDateProps({ onChange, onBlur })

                                                            return (
                                                                <TextField {...ccExpireProps} inputRef={ref} {...input} {...meta} {...props}/>
                                                            )
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={6} className="mb-4">
                                                    <label htmlFor="cvc">Security Code *</label>
                                                    <Field
                                                        name="cvc"
                                                        fullWidth={true}
                                                        render={({ input: { onChange, onBlur, ...input}, meta, ...props }) => {
                                                            const {
                                                                ref,
                                                                ...ccCodeProps
                                                            } = getCVCProps({ onChange, onBlur })

                                                            return (
                                                                <TextField {...ccCodeProps} inputRef={ref} {...input} {...meta} {...props}/>
                                                            )
                                                        }}
                                                    />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-whats-this-cc">
                                                                The final three digits of the number printed on the signature strip on the reverse of your card.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className={cx("d-inline-flex align-items-center mt-2", styles.whatsThis)}>
                                                            <Icon className="mr-2" fontSize="small">
                                                                <FaQuestionCircle />
                                                            </Icon>
                                                            What is this?
                                                        </div>
                                                    </OverlayTrigger>

                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container className="mb-5 mt-5">
                                            <Row className="mb-2">
                                                <Field
                                                    name="acceptTerms"
                                                    label="I accept the Terms and Conditions of travel and understand the terms for the tour I have selected."
                                                    validate={isRequired}
                                                    render={({ input, meta, ...props }) => (
                                                        <Checkbox
                                                            {...input}
                                                            {...meta}
                                                            {...props}
                                                            onChange={(ev) => {
                                                                if(input.value) {
                                                                    mutators.onAcceptTerms(false)
                                                                } else {
                                                                    setShowTermsModal(true)
                                                                }
                                                            }} />
                                                    )}
                                                />

                                            </Row>
                                            {/* 
                                            
                                            <Row className="mb-2">
                                                <Field
                                                    name="acceptMessages"
                                                    label="Receive text message updates about your booking. Message rates may apply."
                                                    render={({ input, meta, ...props }) => (
                                                        <Checkbox {...input} {...meta} {...props} />
                                                    )}
                                                />
                                            </Row>
                                            <Row className="mb-2">
                                                <Field
                                                    name="acceptEmails"
                                                    label="Receive email special offers, inspiration, tips, and other updates from AwesomeNZ. I can unsubscribe at any time."
                                                    render={({ input, meta, ...props }) => (
                                                        <Checkbox {...input} {...meta} {...props} />
                                                    )}
                                                />
                                            </Row>

                                            */}

                                        </Container>

                                        <hr className="mb-4 mt-4" />

                                        {/* Checkboxes, terms and conditions and submit button */}
                                        <Container className="pl-lg-3">
                                            <ul className={styles.dotList}>
                                                <li>You will be charged the total amount once your order is confirmed</li>
                                                <li>You can cancel for free up to 2 hours before the day of the experience, local time</li>
                                            </ul>

                                            <Link className="d-inline-flex align-items-center font-weight-bold" to="/cart">
                                                <Icon className="mr-2" fontSize="small">
                                                    <FaArrowLeft />
                                                </Icon>
                                                I want to make a change to this booking
                                            </Link>
                                        </Container>


                                    </Col>

                                    <Col lg={4} className="pl-md-2 pr-md-2 mt-4 mt-lg-0">
                                        <Container className={styles.confirmAndPay}>
                                            <Typography className="mt-0 mb-1" variant="h2" color="brand-color-2">
                                                Review Order Details
                                            </Typography>

                                            {/* Cart Item */}
                                            {map(cartItems, (item, key) => (
                                                <CheckoutItem key={key} {...item} />
                                            ))}

                                            <Row className="d-flex-space-between">
                                                <Col md="4">
                                                    <Typography className={styles.cartTotal}>
                                                        Total:
                                                    </Typography>
                                                </Col>
                                                <Col className='text-right' md="8">
                                                    <Typography className={styles.cartTotal}>
                                                        ${cartTotal.toFixed(2)}
                                                    </Typography>
                                                    <p>No additional taxes & fees</p>
                                                </Col>
                                            </Row>

                                            <Button className="text-uppercase mt-4" size="lg" block type="submit" disabled={!cartTotal || disableButton}>
                                                Accept & Pay
                                            </Button>
                                        </Container>

                                        <Guarantee />
                                    </Col>
                                </Row>

                                <Modal
                                    show={showTermsModal}
                                    onHide={() => {
                                        mutators.onAcceptTerms(false)
                                        setShowTermsModal(false)
                                    }}
                                    scrollable
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Terms and Conditions
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <div 
                                            className={styles.termConditionPopup}
                                            style={{
                                                paddingTop: 15
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: termsAndConditions1
                                            }}
                                        />
                                    </Modal.Body>

                                    <Modal.Footer className="d-flex justify-content-end">
                                        <Button variant="primary" onClick={(ev) => {
                                            mutators.onAcceptTerms(true)
                                            setShowTermsModal(false)
                                        }}>I accept</Button>
                                    </Modal.Footer>
                                </Modal>

                                <FormSpy
                                    subscription={{ submitFailed: true }}
                                    onChange={() => {
                                        scrollToTarget('.field-error', { offsetTop: 90 })
                                    }}
                                />

                            </Container>
                        </form>
                    )
                }}
            />
        </>
    )
}

export default CheckoutTemplate
